import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Offer, OfferParameter } from './types'

export const ADD_PARAMETER_TO_OFFER = 'ADD_PARAMETER_TO_OFFER'
export const REMOVE_PARAMETER_FROM_OFFER = 'REMOVE_PARAMETER_FROM_OFFER'
export const UPDATE_PARAMETER_TO_OFFER = 'UPDATE_PARAMETER_TO_OFFER'
export const UPDATE_PARAMETERS_TO_OFFER = 'UPDATE_PARAMETERS_TO_OFFER'

export const addParameterToOffer = (
  parameter: OfferParameter,
  offerId: OfferParameter['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/offers/${offerId}/parameter`, {
        value: parameter.value,
        parameterId: parameter.parameterId,
        parameterOrder: parameter.parameterOrder,
        description:
          (parameter.description !== null && parameter.description) || '',
        serviceId:
          (parameter.serviceId !== null && parameter.serviceId) || undefined,
      }),
    successText: 'Parameter added to offer',
  })

  if (createResponse) {
    dispatch({
      type: ADD_PARAMETER_TO_OFFER,
      payload: {
        parameter,
      },
    })
  }
}

export const updateParameterToOffer = (
  parameter: OfferParameter,
  offerId: OfferParameter['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/offers/${offerId}/parameter`, {
        value: parameter.value,
        parameterId: parameter.parameterId,
        description:
          (parameter.description !== null && parameter.description) || '',
        serviceId:
          (parameter.serviceId !== null && parameter.serviceId) || undefined,
        parameterOrder: parameter.parameterOrder,
      }),
    successText: 'Parameter value updated',
  })

  if (updateResponse) {
    if (updateResponse.data.id) {
      dispatch({
        type: ADD_PARAMETER_TO_OFFER,
        payload: {
          parameter,
        },
      })
    }

    if (!updateResponse.data.id) {
      dispatch({
        type: UPDATE_PARAMETER_TO_OFFER,
        payload: { parameter },
      })
    }
  }
}

export const updateParametersToOffer = (
  parameters: OfferParameter[],
  offerId: OfferParameter['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/offers/${offerId}/parameters`, parameters),
    successText: 'Parameters value updated',
  })

  if (updateResponse) {
    dispatch({
      type: UPDATE_PARAMETERS_TO_OFFER,
      payload: parameters,
    })
  }
}

export const removeParameterFromOffer = (
  parameterId: OfferParameter['id'],
  offerId: Offer['id'],
  serviceId?: OfferParameter['serviceId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(
        serviceId
          ? `/offers/${offerId}/parameter/${parameterId}/${serviceId}`
          : `/offers/${offerId}/parameter/${parameterId}`
      ),
    successText: 'Parameter removed from offer',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_PARAMETER_FROM_OFFER,
      payload: {
        offerId,
        parameterId,
        serviceId: serviceId ? serviceId : null,
      },
    })
  }
}
