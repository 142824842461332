import { AnyAction, Dispatch } from 'redux'
import { store } from '../../App'

import axios, { makeRequest } from '../../utils/axios'
import { enqueueSnackbar } from '../Notifier/notificationsActions'
import { OfferCountingCostsValue } from './types'

export const UPDATE_OFFER_COUNTING_COSTS_VALUE =
  'UPDATE_OFFER_COUNTING_COSTS_VALUE'

export const updateOfferCountingCostsValues = (
  countingCostsId: OfferCountingCostsValue['countingCostsId'],
  offerCountingCostsValues: OfferCountingCostsValue[]
) => async (dispatch: Dispatch<AnyAction>) => {
  console.log('🚀 ~ offerCountingCostsValues:', offerCountingCostsValues)
  const maxOfferCountingCostsValuesPerRequest = 999

  if (
    offerCountingCostsValues.length <= maxOfferCountingCostsValuesPerRequest
  ) {
    const createResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(
          `/offers/counting-costs/${countingCostsId}/counting-costs-values`,
          offerCountingCostsValues
        ),
      successText: 'Offer Counting Costs Values Updated',
    })

    if (createResponse) {
      dispatch({
        type: UPDATE_OFFER_COUNTING_COSTS_VALUE,
        payload: {
          countingCostsId,
          offerCountingCostsValues,
        },
      })
    }
  } else {
    const offerCountingCostsValuesAll = [...offerCountingCostsValues]
    const offerCountingCostsValuesChunks = [
      ...Array(
        Math.ceil(
          offerCountingCostsValuesAll.length /
            maxOfferCountingCostsValuesPerRequest
        )
      ),
    ].map((_) =>
      offerCountingCostsValuesAll.splice(
        0,
        maxOfferCountingCostsValuesPerRequest
      )
    )

    Promise.all(
      offerCountingCostsValuesChunks.map((offerCountingCostsValuesChunk) =>
        axios.patch(
          `/offers/counting-costs/${countingCostsId}/counting-costs-values`,
          offerCountingCostsValuesChunk
        )
      )
    ).then((_) => {
      dispatch({
        type: UPDATE_OFFER_COUNTING_COSTS_VALUE,
        payload: {
          countingCostsId,
          offerCountingCostsValues,
        },
      })

      store.dispatch(
        enqueueSnackbar({
          message: 'Offer Counting Costs Values Updated',
          options: { variant: 'success' },
        })
      )
    })
  }
}
