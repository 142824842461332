import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { dispatchResetReducer } from '../../utils/baseReducers'

export const COUNTING_COSTS_TYPES_GET = 'COUNTING_COSTS_TYPES_GET'
export const RESET_COUNTING_COSTS_TYPES = 'RESET_COUNTING_COSTS_TYPES'

export const getCountingCostsTypes = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatchResetReducer(dispatch, RESET_COUNTING_COSTS_TYPES)
  const response = await makeRequest({
    axiosRequest: () => axios.get(`/counting-costs-type`),
  })

  if (response && response.data) {
    dispatch({
      type: COUNTING_COSTS_TYPES_GET,
      payload: response.data,
    })
  }
}
