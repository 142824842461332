import {
  getAll,
  initialState,
  InitialStateType,
} from '../../utils/baseReducers'
import {
  COUNTING_COSTS_TYPES_GET,
  RESET_COUNTING_COSTS_TYPES,
} from './countingCostsTypesActions'
import { CountingCostsTypesActionsTypes } from './types'

const countingCostsTypesReducer = (
  state: InitialStateType = initialState,
  action: CountingCostsTypesActionsTypes
) => {
  switch (action.type) {
    case COUNTING_COSTS_TYPES_GET: {
      return getAll(state, action.payload)
    }

    case RESET_COUNTING_COSTS_TYPES: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default countingCostsTypesReducer
