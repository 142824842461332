import {
  getAll,
  initialState,
  InitialStateType,
  update,
} from '../../utils/baseReducers'
import {
  OFFER_PARAMETER_UPDATE,
  OFFERS_PARAMETERS_GET,
  RESET_OFFERS_PARAMETERS,
} from './offersParametersActions'
import { OffersParametersActionsTypes } from './types'

const offersParametersReducer = (
  state: InitialStateType = initialState,
  action: OffersParametersActionsTypes
) => {
  switch (action.type) {
    case OFFERS_PARAMETERS_GET: {
      return getAll(state, action.payload)
    }

    case OFFER_PARAMETER_UPDATE: {
      return update(state, action.payload)
    }

    case RESET_OFFERS_PARAMETERS: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default offersParametersReducer
