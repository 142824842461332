import { isLoggedIn } from 'axios-jwt'
import React from 'react'

type Props = {
  history: {
    push: (val: string) => void
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (ComposedComponent: any) {
  class AuthenticateProvider extends React.Component<
    Props,
    Record<string, unknown>
  > {
    componentDidMount() {
      this.checkAndRedirect()
    }

    componentDidUpdate() {
      this.checkAndRedirect()
    }

    // eslint-disable-next-line no-underscore-dangle
    checkAndRedirect() {
      if (!isLoggedIn()) {
        this.props.history.push('/auth/login')
      }
    }

    render() {
      return isLoggedIn() ? <ComposedComponent {...this.props} /> : null
    }
  }

  return AuthenticateProvider
}
