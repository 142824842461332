import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { User } from './types'

export const USERS_GET = 'USERS_GET'
export const USER_ADD = 'USER_ADD'
export const USER_REMOVE = 'USER_REMOVE'

export const getAllUsers = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/users'),
  })

  if (getResponse) {
    dispatch({
      type: USERS_GET,
      payload: getResponse.data,
    })
  }
}

export const addUser = (user: User) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/users', {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
      }),
    successText: 'User added',
  })

  if (createResponse) {
    dispatch({
      type: USER_ADD,
      payload: createResponse.data,
    })
  }
}

export const removeUser = (user: User) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/users/${user.id}`),
    successText: 'User removed',
  })

  if (createResponse) {
    user.isActive = false

    dispatch({
      type: USER_REMOVE,
      payload: user,
    })
  }
}
